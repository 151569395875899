import TransactionView from "./Desktop/TransactionView";
import config from "../../config/constants/config";
import titles from "../../config/constants/titles";
import AccordianTransactionTable from "./Mobile/AccordianTransaction";
import moment from "moment";
import PaymentInformationMobile from "./Mobile/PaymentInformation";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import messages from "../../config/constants/messages";

const tabs = {
  NETWELL: [],
  UHF: [],
  INNOVATION: [],
};

export const access = {
  control:
    process.env.REACT_APP_ENV_NAME === "UHF"
      ? {
          showTransaction: true,
          showPaymentInformation: true,
          showchangePaymentMethod: true,
          showChangeBillingDate: true,
          changePaymentAccess: true,
          showPaymentType: true,
          showNextPaymentDueDate: true,
          type: "UHF",
        }
      : process.env.REACT_APP_ENV_NAME === "NETWELL"
      ? {
          showTransaction: true,
          showPaymentInformation: false,
          showchangePaymentMethod: true,
          showChangeBillingDate: false,
          changePaymentAccess: true,
          showPaymentType: true,
          showNextPaymentDueDate: true,
          type: "NETWELL",
        }
      : process.env.REACT_APP_ENV_NAME === "INNOVATION"
      ? {
          showTransaction: true,
          showPaymentInformation: false,
          showchangePaymentMethod: false,
          showChangeBillingDate: false,
          changePaymentAccess: false,
          showPaymentType: false,
          showNextPaymentDueDate: false,
          type: "INNOVATION",
        }
      : {},
};
const footerText = {
  NETWELL: messages.netwell.NEEDHELP_MSG,
  UHF: messages.uhf.NEEDHELP_MSG,
  INNOVATION: "",
};

export const ProductTileInfo = {
  title: titles.myTransaction,
  Component: TransactionView,
  footerText: footerText[config.APP_NAME as keyof typeof tabs],
  showChatIcon: process.env.REACT_APP_ENV_NAME !== "INNOVATION",
};
export const TransactionAccordian = {
  titleOne: titles.recentTransaction,
  ComponentOne: AccordianTransactionTable,
  titleTwo: titles.paymentInfo,
  ComponentTwo: PaymentInformationMobile,
};

export const tableHeader = {
  heading:
    process.env.REACT_APP_ENV_NAME === "UHF"
      ? {
          parent: [
            "Transaction ID",
            "Transaction Date",
            "Payment Method",
            "Total Amount",
            "Status",
          ],
          child: [
            "Monthly Share Contribution",
            "UHF Monthly Membership Fee",
            "Application Fee",
            "Refund Amount",
          ],
        }
      : process.env.REACT_APP_ENV_NAME === "NETWELL"
      ? {
          parent: [
            "Transaction ID",
            "Transaction Date",
            "Payment Method",
            "Total Amount",
            "Status",
          ],
          child: [
            "Monthly Share Contribution",
            "Monthly Membership Fee",
            "Application Fee",
            "Refund Amount",
          ],
        }
      : process.env.REACT_APP_ENV_NAME === "INNOVATION"
      ? {
          parent: [
            "Transaction ID",
            "Transaction Date",
            "Payment Method",
            "Total Amount",
            "Status",
          ],
          child: [
            "Essential Amount",
            "Guardian Amount",
            "Dental Amount",
            "Program Amount",
            "Enrollment Fee",
          ],
        }
      : { parent: [], child: [] },
};
export const getDate = (date: any) => {
  const isDateValid = date ? date : false;
  if (isDateValid) {
    return moment(date).format("MMMM D, YYYY");
  }
  return date;
};

export const numberWithCommas = (x: any) => {
  return Number(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const floatconversion = (x: any) => {
  if (x.toString().startsWith("$")) {
    let substr = x.substr(1, x.length);
    return "$" + parseFloat(substr).toFixed(2).toString();
  } else {
    return x.toFixed(2);
  }
};
