import { useEffect, useState } from "react";
import { storeTransaction } from "../../handlers";
import moment from "moment";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Tab, Tabs, TextField, Button } from "@mui/material";
import RadioButtonActive from "../../../../assests/images/radioButtonChecked.png";
import RadioButtonInActive from "../../../../assests/images/radioButtonUnchecked.png";
import styled from "@mui/material/styles/styled";
import CustomTextField from "./sampleTextField";
import CommonDropDwn from "./CommonDropDwn";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import messages from "../../../../config/constants/messages";
import DialogMui from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ApiLoader } from "carynhealth-component-library";
import StyleWrapper from "../../Style";
import StyleWapper from "./styleWapper";

const AntTab = styled(Tab)(() => ({
  textTransform: "none",
  width: "180px",
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "0.75px",
  borderBottom: "1px solid #999999",
  backgroundColor: "#ffffff",
  "&:hover": {
    color: "#533278",
  },
  "&$selected": {
    color: "#533278",
    backgroundColor: "#533278 !important",
  },
  "&:focus": {
    color: "#533278",
    outline: "none",
  },
}));
const AntTabs = styled(Tabs)(() => ({
  "&.MuiTabs-indicator": {
    color: "#533278",
    backgroundColor: "#533278 !important",
  },
  ".Mui-selected": {
    border: "none",
    color: "#533278",
  },
  ".MuiTabs-indicator": {
    backgroundColor: "#533278",
  },
}));

const ModalChangePaymentMethod = (props: any) => {
  const [activeTab, setActiveTab] = useState(0);
  const [firstTab, setFirstTab] = useState(RadioButtonActive);
  const [modal, SetModal] = useState(props.openModalChangePayment);
  const [secondTab, setSencondTab] = useState(RadioButtonInActive);
  const [loader, setLoader] = useState(false);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const years = [
    2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034,
  ];
  const accountTypes = ["CHECKING", "SAVINGS"];

  const [bankName, setBankName] = useState("");
  const [nameonAccount, setNameonAccount] = useState(
    localStorage.getItem("subscriberName")
  );
  const [accountType, setAccountType] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState(
    localStorage.getItem("subscriberName")
  );
  const [activeOneDisable, setActiveOneDisable] = useState(true);
  const [activeTwoDisable, setActiveTwoDisable] = useState(true);
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [cvv, setCvv] = useState("");

  const [errCodeACH, seterrCodeACH] = useState(false);
  const [MsgModalerror, setMsgModalerror] = useState("");
  const [errCodeCC, seterrCodeCC] = useState(false);

  const [MsgModalerrorFooterACH, setMsgModalerrorFooterACH] = useState("");
  const [MsgModalerrorFooterCC, setMsgModalerrorFooterCC] = useState("");

  useEffect(() => {
    validateForm();
  }, [routingNumber]);
  useEffect(() => {
    validateForm();
  }, [accountType]);
  useEffect(() => {
    validateForm();
  }, [nameonAccount]);
  useEffect(() => {
    validateForm();
  }, [bankName]);
  useEffect(() => {
    validateForm();
  }, [accountNumber]);
  useEffect(() => {
    validateForm();
  }, [cvv]);
  useEffect(() => {
    validateForm();
  }, [cardNumber]);
  useEffect(() => {
    validateForm();
  }, [cardHolderName]);
  useEffect(() => {
    validateForm();
  }, [expirationMonth]);
  useEffect(() => {
    validateForm();
  }, [expirationYear]);

  const refreshState = () => {
    setBankName("");
    setAccountType("");
    setAccountNumber("");
    setCardNumber("");
    setRoutingNumber("");
    setActiveOneDisable(true);
    setActiveTwoDisable(true);
    setExpirationMonth("");
    setExpirationYear("");
    setCvv("");
    seterrCodeACH(false);
    setMsgModalerror("");
    seterrCodeCC(false);
    setMsgModalerrorFooterACH("");
    setMsgModalerrorFooterCC("");
  };
  const handleChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
    refreshState();
    if (newValue == 0) {
      setFirstTab(RadioButtonActive);
      setSencondTab(RadioButtonInActive);
    } else {
      setFirstTab(RadioButtonInActive);
      setSencondTab(RadioButtonActive);
    }
  };
  const changeText = (val: any, isValid: any, parentObj: any) => {
    if (parentObj.label === "bank_name") {
      if (isValid) {
        setBankName(val);
        validateForm();
      } else {
        setBankName("");
        validateForm();
      }
    } else if (parentObj.label === "Account_Name") {
      if (isValid) {
        setNameonAccount(val);
        validateForm();
      } else {
        setNameonAccount("");
        validateForm();
      }
    } else if (parentObj.label === "Routing_Number") {
      if (isValid) {
        setRoutingNumber(val);
        seterrCodeACH(false);
        validateForm();
      } else {
        setRoutingNumber("");
        validateForm();
      }
    } else if (parentObj.label === "Account_Type") {
      if (isValid) {
        setAccountType(val);
        validateForm();
      } else {
        setAccountType("");
        validateForm();
      }
    } else if (parentObj.label === "Account_Number") {
      if (isValid) {
        setAccountNumber(val);
        validateForm();
      } else {
        setAccountNumber("");
        validateForm();
      }
    } else if (parentObj.label === "Card_Number") {
      if (isValid) {
        setCardNumber(val);
        setMsgModalerror("");
        seterrCodeCC(false);
        validateForm();
      } else {
        setCardNumber("");
        validateForm();
      }
    } else if (parentObj.label === "Holder_Name") {
      if (isValid) {
        setCardHolderName(val);
        validateForm();
      } else {
        setCardHolderName("");
        validateForm();
      }
    } else if (parentObj.label === "expiryMonth") {
      if (isValid) {
        setExpirationMonth(val);
        validateForm();
      } else {
        setExpirationMonth("");
        validateForm();
      }
    } else if (parentObj.label === "expiryYear") {
      if (isValid) {
        setExpirationYear(val);
        validateForm();
      } else {
        setExpirationYear("");
        validateForm();
      }
    } else if (parentObj.label === "cvv") {
      if (isValid) {
        setCvv(val);
        validateForm();
      } else {
        setCvv("");
        validateForm();
      }
    }
  };
  const changePaymentMethod = () => {
    setLoader(true);
    var payType = "";
    if (activeTab == 0) {
      payType = "ACH";
    } else {
      payType = "CC";
    }
    let obj = {
      source: getLocalStorage("sourceid"),
      bankName: bankName,
      accountName: nameonAccount,
      accountNumber: accountNumber,
      accountType: accountType,
      routingNo: routingNumber,

      cardNumber: cardNumber,
      holderName: cardHolderName,
      expiryMonth: expirationMonth
        ? moment().month(expirationMonth).format("M")
        : "",
      expiryYear: expirationYear,
      cvv: cvv,
      paymentType: payType,
    };
    storeTransaction(obj)
      .then((res) => {
        setLoader(false);
        if (res.data.code == 200) {
          props.closePopup();
          refreshState();
        } else if (res.data.code == 202) {
          let x = JSON.parse(res.data.response).error_message;
          let errMsg = "";
          if (x.includes("-")) {
            let cds = x.split(" - ");
            errMsg = cds[1];
          } else {
            errMsg = x;
          }
          setMsgModalerror(errMsg);
          seterrCodeACH(true);
          if (activeTab == 0 && MsgModalerror != "") {
            seterrCodeACH(true);
            setCvv("");
            setCardNumber("");
            seterrCodeCC(false);
            setExpirationMonth("");
            setExpirationYear("");
            let evt = new CustomEvent("errorCode", { detail: { flag: true } });
            window.dispatchEvent(evt);
          }

          if (activeTab == 1) {
            seterrCodeCC(true);
            setBankName("");
            setAccountNumber("");
            setAccountType("");
            setRoutingNumber("");
            seterrCodeACH(false);
            let evt = new CustomEvent("errorCode", { detail: { flag: true } });
            window.dispatchEvent(evt);
          }
        } else if (res.data.code == 204) {
          if (activeTab == 0) {
            setMsgModalerrorFooterACH("Source is not registered");
            setMsgModalerrorFooterCC("");
            setCvv("");
            setCardNumber("");
            seterrCodeCC(false);
            setExpirationMonth("");
            setExpirationYear("");
          } else {
            setMsgModalerrorFooterCC("Source is not registered");
            setMsgModalerrorFooterACH("");
            setBankName("");
            setAccountNumber("");
            setAccountType("");
            setRoutingNumber("");
          }
        } else if (res.data.code == 500) {
          if (activeTab == 0) {
            setMsgModalerrorFooterACH("Internal server error");
            setMsgModalerrorFooterCC("");
            setCvv("");
            setCardNumber("");
            seterrCodeCC(false);
            setExpirationMonth("");
            setExpirationYear("");
          } else {
            setMsgModalerrorFooterCC("Internal server error");
            setMsgModalerrorFooterACH("");
            setBankName("");
            setAccountNumber("");
            setAccountType("");
            setRoutingNumber("");
          }
        } else {
          let x = JSON.parse(res.data.response).error_message;
          let cds = x.split(" - ");
          if (activeTab == 0) {
            setMsgModalerrorFooterACH(cds[1]);
            setMsgModalerrorFooterCC("");
            setCvv("");
            setCardNumber("");
            seterrCodeCC(false);
            setExpirationMonth("");
            setExpirationYear("");
          } else {
            setMsgModalerrorFooterCC(cds[1]);
            setMsgModalerrorFooterACH("");
            setBankName("");
            setAccountNumber("");
            setAccountType("");
            setRoutingNumber("");
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  const validateForm = () => {
    if (activeTab === 0) {
      if (
        bankName !== "" &&
        nameonAccount !== "" &&
        accountNumber !== "" &&
        accountType !== "" &&
        routingNumber !== ""
      ) {
        setActiveOneDisable(false);
      } else {
        setActiveOneDisable(true);
      }
    } else if (activeTab === 1) {
      if (
        cardNumber !== "" &&
        cardHolderName !== "" &&
        expirationMonth !== "" &&
        expirationYear !== "" &&
        cvv !== ""
      ) {
        setActiveTwoDisable(false);
      } else {
        setActiveTwoDisable(true);
      }
    }
  };
  const closePopupModal = () => {
    refreshState();
    props.closePopup();
  };
  return (
    <>
      <StyleWrapper>
        {loader && <ApiLoader />}
        <DialogMui
          open={props.openModalChangePayment}
          onClose={() => closePopupModal()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent dividers>
            <>
              {loader && <ApiLoader />}
              <StyleWapper>
                <Grid
                  container
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="change_Payment_Method">
                    {messages.changePaymentMethod.toLowerCase()}
                  </span>
                  <IconButton
                    aria-label="close"
                    style={{ marginTop: "-13px" }}
                    onClick={() => closePopupModal()}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <p className="description-text">
                      {messages.PaymentInformationMessage}
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #999999",
                  }}
                >
                  <Grid item xs={12} sm={5}>
                    <AntTabs
                      value={activeTab}
                      onChange={(event, newValue) =>
                        handleChange(event, newValue)
                      }
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <AntTab
                        label="ACH DEBIT"
                        className="ant-col-15 tabBtn tabFirst"
                        iconPosition="start"
                        icon={<img className="raidoBtn" src={firstTab} />}
                      />
                      <AntTab
                        label="CREDIT CARD"
                        className="ant-col-15 tabBtn tabSecond"
                        iconPosition="start"
                        icon={<img className="raidoBtn" src={secondTab} />}
                      />
                    </AntTabs>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <table className="Last_4_digit">
                      <td className="td_style1">
                        {messages.AccountNumberOnFile}
                      </td>
                      <td
                        className="td_style"
                        style={{ verticalAlign: "middle" }}
                      >
                        {props.accountNo}
                      </td>
                    </table>
                  </Grid>
                </Grid>
                <Grid></Grid>
                {activeTab == 0 ? (
                  <>
                    <Grid container spacing={2} style={{ marginTop: "1%" }}>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{ marginBottom: "-1%" }}
                          >
                            <CustomTextField
                              label={"Bank Name"}
                              value={bankName}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"Bank_Name"}
                              reqFlag={true}
                              disable={false}
                              length={120}
                              fieldType={"bank_name"}
                              errMsg={"Enter valid bank name"}
                              helperMsg={"Bank name required"}
                              parentDetails={{ label: "bank_name" }}
                              key={0}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{ marginBottom: "-1%" }}
                          >
                            <CustomTextField
                              value={nameonAccount}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"Account_Name"}
                              reqFlag={true}
                              label={"Name on Account"}
                              disable={false}
                              length={120}
                              fieldType={"accountName"}
                              errMsg={"Enter valid account name"}
                              helperMsg={"Name on account required"}
                              parentDetails={{ label: "Account_Name" }}
                              key={0}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{ marginBottom: "-1%" }}
                          >
                            <CommonDropDwn
                              value={accountType}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"AccountType"}
                              label={"Account Type"}
                              disable={false}
                              fieldType={"dropDwn"}
                              helperMsg={"Select account type"}
                              List={accountTypes}
                              parentDetails={{ label: "Account_Type" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          style={{ marginTop: "1.5%" }}
                        >
                          <Grid item xs={12} sm={6}>
                            <CustomTextField
                              value={routingNumber}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"Routing_Number"}
                              MsgModalerror={MsgModalerror}
                              ErrCode={errCodeACH}
                              reqFlag={true}
                              label={"Routing Number"}
                              disable={false}
                              length={9}
                              fieldType={"routingNo"}
                              errMsg={"Enter valid routing number"}
                              helperMsg={"Routing number required"}
                              parentDetails={{ label: "Routing_Number" }}
                              key={0}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CustomTextField
                              value={accountNumber}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"Account_Number"}
                              reqFlag={true}
                              label={"Account Number"}
                              disable={false}
                              length={26}
                              fieldType={"accountNumber"}
                              errMsg={
                                "Account number must be 4 and up to 26 digits"
                              }
                              helperMsg={"Account number required"}
                              parentDetails={{ label: "Account_Number" }}
                              key={0}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2} style={{ marginTop: "1%" }}>
                      <Grid item xs={12} sm={12}>
                        <Grid
                          container
                          spacing={3}
                          style={{ marginBottom: "1.5%" }}
                        >
                          <Grid item xs={12} sm={6}>
                            <CustomTextField
                              value={cardNumber}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"Card_Number"}
                              MsgModalerror={MsgModalerror}
                              ErrCode={errCodeCC}
                              reqFlag={true}
                              label={"Card Number"}
                              disable={false}
                              length={16}
                              fieldType={"cardNumber"}
                              errMsg={"Card number up to 16 digits"}
                              helperMsg={"Card number required"}
                              parentDetails={{ label: "Card_Number" }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CustomTextField
                              value={cardHolderName}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"Holder_Name"}
                              reqFlag={true}
                              label={"Card Holder Name"}
                              disable={false}
                              length={25}
                              fieldType={"holderName"}
                              errMsg={"Enter valid card holder name"}
                              helperMsg={"Card holder name required"}
                              parentDetails={{ label: "Holder_Name" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{ marginBottom: "-1%" }}
                          >
                            <CommonDropDwn
                              value={expirationMonth}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"expiryMonth"}
                              label={"Expiration Month"}
                              disable={false}
                              fieldType={"dropDwn"}
                              helperMsg={"Select expiration month"}
                              errMsg={
                                "The expiration date is before today's date. Enter valid expiration month"
                              }
                              List={months}
                              parentDetails={{ label: "expiryMonth" }}
                              key={1}
                            ></CommonDropDwn>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{ marginBottom: "-1%" }}
                          >
                            <CommonDropDwn
                              value={expirationYear}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"expiryYear"}
                              label={"Expiration Year"}
                              disable={false}
                              fieldType={"dropDwn"}
                              helperMsg={"Select expiration year"}
                              errMsg={
                                "The expiration date is before today's date. Enter valid expiration year"
                              }
                              List={years}
                              parentDetails={{ label: "expiryYear" }}
                              key={1}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{ marginBottom: "-1%" }}
                          >
                            <CustomTextField
                              value={cvv}
                              setChild={(
                                val: any,
                                isValid: any,
                                parentObj: any
                              ) => changeText(val, isValid, parentObj)}
                              name={"cvv"}
                              label={"CVV"}
                              reqFlag={true}
                              disable={false}
                              length={4}
                              fieldType={"cvv"}
                              errMsg={"Enter valid CVV"}
                              helperMsg={"CVV required"}
                              parentDetails={{ label: "cvv" }}
                              key={1}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </StyleWapper>
            </>
          </DialogContent>

          <DialogActions>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {activeTab === 0 ? (
                <span className="Footer-error-msg" style={{ width: "418px" }}>
                  {MsgModalerrorFooterACH}
                </span>
              ) : (
                <span className="Footer-error-msg" style={{ width: "418px" }}>
                  {MsgModalerrorFooterCC}
                </span>
              )}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  style={{ marginRight: "15px" }}
                  className="ButtonBG"
                  onClick={() => closePopupModal()}
                >
                  Cancel
                </Button>
                <Button
                  style={{ marginRight: "15px" }}
                  disabled={
                    activeTab == 0 ? activeOneDisable : activeTwoDisable
                  }
                  className={
                    (activeTab == 0 ? activeOneDisable : activeTwoDisable)
                      ? "ButtonBG_Disable"
                      : "ButtonBG"
                  }
                  onClick={() => changePaymentMethod()}
                >
                  Done
                </Button>
              </div>
            </div>
          </DialogActions>
        </DialogMui>
      </StyleWrapper>
    </>
  );
};
export default ModalChangePaymentMethod;
