import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Addons,
  Button,
  StorageHelpers,
  Dialog,
  useCommonLoader,
} from "carynhealth-component-library";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import { getEncryptData } from "../handlers";
import { getPlainIData } from "../../RightTopMemberTabs/handler";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import messages from "../../../config/constants/messages";
import config from "../../../config/constants/config";
import titles from "../../../config/constants/titles";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";

const AddonsStyle = styled(Grid)(({ theme }) => {
  return {
    ".manage-addons": {
      margin: "20px 0",
      "&.disabled .MuiButton-root ": {
        color: "#ccc !important",
        borderColor: "#ccc",
      },
      ".MuiButtonBase-root": {
        width: 190,
        borderRadius: 30,
        border: "2px solid #41b5c2",
        fontFamily: "Oswald",
        fontSize: 20,
        fontWeight: 600,
        textAlign: "center",
        color: "#41b5c2 !important",
        backgroundColor: "#fff",
        padding: "1px 4px",
      },
    },
    ".addon_desc": {
      margin: "16px 16px 16px 0",
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontWeight: "300",
      color: "#212529",
    },
    [theme.breakpoints.down(426)]: {
      ".manage-addons": {
        ".MuiButtonBase-root": {
          padding: "3px 10px",
        },
      },
    },
  };
});

const StyleWrapper = styled("div")(({ theme }) => {
  return {
    whiteSpace: "pre",
    fontWeight: 500,
    [theme.breakpoints.down(426)]: {
      whiteSpace: "normal",
    },
  };
});

const AddonsServices: React.FC<{
  data: any;
  agentDetails: any;
  clientDetails: any;
  memberDetails: any;
}> = (props) => {
  const manageAddons = useCallback(async () => {
    if (config.APP_NAME === config.BRAND_INNOVATION) {
      setPopup(true);
      unSetLoading();
      return;
    }
    try {
      const reqEncrypt = {
        clientId: props.clientDetails?.clientName || "",
        associationId: props.clientDetails?.associationId || "",
        brokerId: props.agentDetails?.brokerId || "",
        fromMember: true,
        isSelectProgram: true,
        user_subId: localStorage.getItem("userMail"),
        memberId: localStorage.getItem("sourceid"),
        subID: props.memberDetails?.subID || "",
      };
      await getEncryptData(reqEncrypt || {});
    } catch (error) {
      console.log("manageAddons Error:: ", error);
    }
  }, []);

  const isDiabled = useMemo(() => {
    return (
      [6548, 4367, 5540, 4376, 5541, 4377].includes(
        Number(StorageHelpers.getLocalStorage("CLIENT_ID"))
      ) || StorageHelpers.getLocalStorage("SOURCE") === "NEO"
    );
  }, []);
  console.clear();
  console.log(props);

  const rxSimpleShare = {
    membersRolled: props?.data?.addOns
      ?.map((val: any) => {
        return (val.addon == "UHS RxSimpleShare" ||
          val.addon == "RxSimpleShare") &&
          val.status == "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">{`${val.prefix || ''} ${val.firstName || ''} ${val.middleName || ''} ${val.lastName || ''} ${val?.suffix || ''}`.trim().replace(/\s+/g, ' ')}</p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    effectiveDate: props?.data?.addOns
      ?.map((val: any) => {
        return (val.addon == "UHS RxSimpleShare" ||
          val.addon == "RxSimpleShare") &&
          val.status == "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">
              {moment(val.addOnEffectiveDate).format("MMM, DD YYYY")}
            </p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    fee: (props?.data?.counterRxSimpleShare || 0) * 25,
  };

  const healthTools = {
    membersRolled: props?.data?.addOns
      ?.map((val: any) => {
        return [
          "UHS Health Tools",
          "Advanced Health Tools",
          "Health Tools",
        ].includes(val.addon) && val.status == "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">{`${val.prefix || ''} ${val.firstName || ''} ${val.middleName || ''} ${val.lastName || ''} ${val?.suffix || ''}`.trim().replace(/\s+/g, ' ')}</p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    effectiveDate: props?.data?.addOns
      ?.map((val: any) => {
        return [
          "UHS Health Tools",
          "Advanced Health Tools",
          "Health Tools",
        ].includes(val.addon) && val.status == "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">
              {moment(val.addOnEffectiveDate).format("MMM, DD YYYY")}
            </p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
  };
  const Guardian = {
    membersRolled: props?.data?.addOns
      ?.map((val: any) => {
        return val.addon.includes("Guardian") && val.status === "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">{`${val.prefix || ''} ${val.firstName || ''} ${val.middleName || ''} ${val.lastName || ''} ${val?.suffix || ''}`.trim().replace(/\s+/g, ' ')}</p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    effectiveDate: props?.data?.addOns
      ?.map((val: any) => {
        return val.addon.includes("Guardian") && val.status === "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">
              {moment(val.addOnEffectiveDate).format("MMM, DD YYYY")}
            </p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    fee: (props?.data?.counterRxSimpleShare || 0) * 25,
  };

  const Essential = {
    membersRolled: props?.data?.addOns
      ?.map((val: any) => {
        return val.addon.includes("Essential") && val.status === "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">{`${val.prefix || ''} ${val.firstName || ''} ${val.middleName || ''} ${val.lastName || ''} ${val?.suffix || ''}`.trim().replace(/\s+/g, ' ')}</p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    effectiveDate: props?.data?.addOns
      ?.map((val: any) => {
        return val.addon.includes("Essential") && val.status === "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">
              {moment(val.addOnEffectiveDate).format("MMM, DD YYYY")}
            </p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
  };

  const Dental = {
    membersRolled: props?.data?.addOns
      ?.map((val: any) => {
        return val.addon.includes("Dental") && val.status === "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">{`${val.prefix || ''} ${val.firstName || ''} ${val.middleName || ''} ${val.lastName || ''} ${val?.suffix || ''}`.trim().replace(/\s+/g, ' ')}</p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    effectiveDate: props?.data?.addOns
      ?.map((val: any) => {
        return val.addon.includes("Dental") && val.status === "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">
              {moment(val.addOnEffectiveDate).format("MMM, DD YYYY")}
            </p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
  };
  const [healthTitle, setHealthTitle] = useState("Health Tools");
  const [healthDesc, setHealthDesc] = useState(
    messages.uhf.ADDON_DESCRIPTIOON.HealthTools
  );
  const [popup, setPopup] = useState(false);
  const { setLoading, unSetLoading } = useCommonLoader();

  useEffect(() => {
    const planID: any = getLocalStorage("planId");
    if (getPlainIData(planID)) {
      setHealthTitle("Advanced Health Tools");
      setHealthDesc(messages.uhf.ADDON_DESCRIPTIOON.AdvancedHealthTools);
    }
  }, []);
  const handleClick = (data: any) => {
    setPopup(false);
  };
  return (
    <AddonsStyle
      id="pi-addons"
      className={`${config.APP_NAME}-addons`}
      container
    >
      {config.APP_NAME === config.BRAND_UHF ? (
        <>
          <Grid item xs={12} md={6} className="tetsting">
            <Addons
              title="RxSimpleShare"
              active={props?.data?.rxSimpleShareActive}
              {...rxSimpleShare}
            />
            <p className="addon_desc">
              {messages.uhf.ADDON_DESCRIPTIOON.RxSimpleShare}
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <Addons
              title={healthTitle}
              active={props?.data?.healthToolAddonActive}
              {...healthTools}
            />
            <p className="addon_desc">{healthDesc}</p>
          </Grid>
        </>
      ) : (
        <Grid className="ip_addon">
          <Grid item xs={12} md={6} className="addon_grid">
            <Addons
              title="Guardian"
              active={props?.data?.GuardianActive}
              {...Guardian}
            />
            <p className="addon_desc">
              {messages.innovative.ADDON_DESCRIPTIOON.Guardian}
            </p>
          </Grid>
          <Grid item xs={12} md={6} className="addon_grid">
            <Addons
              title="Essential"
              active={props?.data?.EssentialActive}
              {...Essential}
            />
            <p className="addon_desc">
              {messages.innovative.ADDON_DESCRIPTIOON.Essential}
            </p>
          </Grid>
          <Grid item xs={12} md={6} className="addon_grid">
            <Addons
              title="Dental"
              active={props?.data?.DentalActive}
              {...Dental}
            />
            <p className="addon_desc">
              {messages.innovative.ADDON_DESCRIPTIOON.Dental}
            </p>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className={`manage-addons ${isDiabled ? "disabled" : ""}`}
      >
        <Button
          disabled={isDiabled}
          label="MANAGE ADD-ONS"
          variant="outlined"
          onClick={manageAddons}
        />
      </Grid>
      <Dialog
        brand={
          config.APP_NAME === config.BRAND_NETWELL
            ? "NETWELL"
            : config.APP_NAME === config.BRAND_UHF
            ? "UHF"
            : "INNOVATION"
        }
        show={popup}
        body={() => (
          <StyleWrapper>
            <p>
              {config.APP_NAME === config.BRAND_NETWELL
                ? ""
                : config.APP_NAME === config.BRAND_INNOVATION
                ? messages.innovative.ManageAddons
                : ""}
            </p>
          </StyleWrapper>
        )}
        CloseIcon={true}
        buttons={[
          {
            label: "OK",
            size: "large",
            variant: "text",
            type: "button",
            className: "OK",
          } as CustomButtonProps,
        ]}
        title={titles.Message}
        handleCallback={(data: any) => {
          handleClick(data);
        }}
      />
    </AddonsStyle>
  );
};

export default AddonsServices;
