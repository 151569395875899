import React from "react";
import { StorageHelpers } from "carynhealth-component-library";
import AgentDetails from "./AgentInfo";
import titles from "../../../../config/constants/titles";
import { getSendNeedsToContactAddress } from "../handler";

const renderElse = [
  "5558",
  "4386",
  "4360",
  "4357",
  "4356",
  "4359",
  "4364",
  "4378",
  "4379",
  "4387",
  "5448",
  "5447",
  "5446",
  "5450",
  "5503",
  "5550",
  "5553",
  "5559",
  "5540",
  "4376",
];

export const ContactUHF: React.FC<any> = (props: any) => {
  console.log("ContactUHF:: ", props.contactInfo.contactInfo);

  if (!props.contactInfo) {
    return (
      <div style={{ padding: "35px" }}>
        <div className="tempModalTxt">
          We’re facing some technical difficulties, due to which this feature is
          currently unavailable. For support, call Member Services at
          {localStorage.getItem("CONTACT_NUMBER")},  Monday through Friday 9:00 am to 6:00 pm EST.
        </div>
      </div>
    );
  }

  if (!props.contactInfo.contactInfo?.contactCardData?.enable) return null;
  return (
    (() => {
      let client_id = localStorage.getItem("CLIENT_ID");
      console.log(1, props.contactInfo.nwName?.network, client_id);
      switch (props.contactInfo.nwName?.network) {
        case "PHCS":
          if (client_id == "5541" || client_id == "4377") {
            return (
              <div className="phcs">
                <div className="contact_infomiddiv text-left">
                  {props.contactInfo.cardIdDetails.contactNumber ? (
                    <>
                      <div className="contactinfo_label">
                        {titles.PRE_NOTIFY_SERVICE}
                      </div>
                      <div className="contactinfo_Value">(877) 987-1233</div>
                    </>
                  ) : null}
                  {canDisplayMedicine() && (
                    <div>
                      <div className="contactinfo_label ">
                        {titles.TELE_MEDICINE}
                      </div>
                      <div className="contactinfo_Value">1 (866) 697-8212</div>
                    </div>
                  )}

                  <div className="contactinfo_label">{titles.SEND_NEEDS}</div>
                  <div className="contactinfo_Value">
                    {props.contactInfo.nwName?.network === "HLC"
                      ? "4555 Mansell Road, Suite 300 Alpharetta, GA 30022"
                      : getSendNeedsToContactAddress()}
                  </div>
                  <AgentDetails agentInfo={props.contactInfo.agentInfo} />
                </div>
              </div>
            );
          } else if (client_id == "3211" || client_id == "3098") {
            return (
              <div className="PHSC">
                <div className="contact_infomiddiv text-left">
                  {props.contactInfo.cardIdDetails.contactNumber ? (
                    <>
                      <div className="contactinfo_label">
                        {titles.PRE_NOTIFY_SERVICE}
                      </div>
                      <div className="contactinfo_Value">(888) 366-6243</div>
                    </>
                  ) : null}
                  {canDisplayMedicine() && (
                    <div>
                      <div className="contactinfo_label ">
                        {titles.TELE_MEDICINE}
                      </div>
                      <div className="contactinfo_Value">(866) 697-8212</div>
                    </div>
                  )}
                  <div className="contactinfo_label">{titles.SEND_NEEDS}</div>
                  <div className="contactinfo_Value">
                    {props.contactInfo.nwName?.network === "HLC"
                      ? "4555 Mansell Road, Suite 300 Alpharetta, GA 30022"
                      : getSendNeedsToContactAddress()}
                  </div>

                  <AgentDetails agentInfo={props.contactInfo.agentInfo} />
                </div>
              </div>
            );
          } else if (renderElse.includes(client_id || "")) {
            return (
              <div className="">
                <div className="contact_infomiddiv text-left">
                  {props.contactInfo.cardIdDetails.contactNumber ? (
                    <>
                      <div className="contactinfo_label">
                        {titles.PRE_NOTIFY_CALL}
                      </div>
                      <div className="contactinfo_Value">(888) 366-6243</div>
                      <div className="contactinfo_label">
                        {titles.CUSTOMER_CALL}
                      </div>
                      <div className="contactinfo_Value">1 (877) 987-1233</div>
                    </>
                  ) : null}
                  {canDisplayMedicine() ? (
                    <div>
                      <div className="contactinfo_label ">
                        {titles.TELE_MEDICINE}
                      </div>
                      <div className="contactinfo_Value">1 (866) 697-8212</div>
                    </div>
                  ) : null}
                  <div className="contactinfo_label">{titles.SEND_NEEDS}</div>
                  <div className="contactinfo_Value">
                    P.O. Box 211223, Eagan, MN 55121
                  </div>
                  <AgentDetails agentInfo={props.contactInfo.agentInfo} />
                </div>
              </div>
            );
          } else {
            return (
              <div className="">
                <div className="contact_infomiddiv text-left">
                  {props.contactInfo.cardIdDetails.contactNumber ? (
                    <>
                      <div className="contactinfo_label">
                        {titles.PRE_NOTIFY_SERVICE}
                      </div>
                      <div className="contactinfo_Value">
                        {props.contactInfo.cardIdDetails.contactNumber}
                      </div>
                    </>
                  ) : null}
                  {canDisplayMedicine() && (
                    <div>
                      <div className="contactinfo_label ">
                        {titles.TELE_MEDICINE}
                      </div>
                      <div className="contactinfo_Value">1 (888) 501-2405</div>
                    </div>
                  )}
                  <div className="contactinfo_label">{titles.SEND_NEEDS}</div>
                  <div className="contactinfo_Value">
                    {props.contactInfo.nwName?.network === "HLC"
                      ? "4555 Mansell Road, Suite 300 Alpharetta, GA 30022"
                      : getSendNeedsToContactAddress()}
                  </div>

                  <AgentDetails agentInfo={props.contactInfo.agentInfo} />
                </div>
              </div>
            );
          }

        case "Smartshare":
          return (
            <div className="">
              <div className="contact_infomiddiv text-left">
                {props.contactInfo.cardIdDetails.contactNumber ? (
                  <>
                    <div className="contactinfo_label">{titles.PRE_NOTIFY}</div>
                    <div className="contactinfo_Value">(888) 366-6243</div>
                    <div className="contactinfo_label">
                      {titles.CUSTOMER_CALL}
                    </div>
                    <div className="contactinfo_Value">1 (877) 987-1233</div>
                  </>
                ) : null}
                {canDisplayMedicine() && (
                  <div>
                    <div className="contactinfo_label ">
                      {titles.TELE_MEDICINE}
                    </div>
                    <div className="contactinfo_Value">1 (866) 697-8212</div>
                  </div>
                )}
                <div className="contactinfo_label">{titles.SEND_NEEDS}</div>
                <div className="contactinfo_Value">
                  P.O. Box 211223, Eagan, MN 55121
                </div>
                <AgentDetails agentInfo={props.contactInfo.agentInfo} />
              </div>
            </div>
          );
        case "smartshare25":
          return (
            <div className="">
              <div className="contact_infomiddiv text-left">
                {props.contactInfo.cardIdDetails.contactNumber ? (
                  <>
                    <div className="contactinfo_label">
                      {titles.PRE_NOTIFY_SERVICE}
                    </div>
                    <div className="contactinfo_Value">
                      {props.contactInfo.cardIdDetails.contactNumber}
                    </div>
                  </>
                ) : null}
                {canDisplayMedicine() && (
                  <div>
                    <div className="contactinfo_label ">
                      {titles.TELE_MEDICINE}
                    </div>
                    <div className="contactinfo_Value">1 (888) 501-2405</div>
                  </div>
                )}
                <div className="contactinfo_label">{titles.SEND_NEEDS}</div>
                <div className="contactinfo_Value">
                  {getSendNeedsToContactAddress()}
                </div>

                <AgentDetails agentInfo={props.contactInfo.agentInfo} />
              </div>
            </div>
          );
        case "smartshare50":
          return (
            <div className="">
              <div className="contact_infomiddiv text-left">
                {props.contactInfo.cardIdDetails.contactNumber ? (
                  <>
                    <div className="contactinfo_label">
                      {titles.PRE_NOTIFY_SERVICE}
                    </div>
                    <div className="contactinfo_Value">
                      {props.contactInfo.cardIdDetails.contactNumber}
                    </div>
                  </>
                ) : null}

                {canDisplayMedicine() && (
                  <div>
                    <div className="contactinfo_label ">
                      {titles.TELE_MEDICINE}
                    </div>
                    <div className="contactinfo_Value">1 (888) 501-2405</div>
                  </div>
                )}
                <div className="contactinfo_label">{titles.SEND_NEEDS}</div>
                <div className="contactinfo_Value">
                  {getSendNeedsToContactAddress()}
                </div>

                <AgentDetails agentInfo={props.contactInfo.agentInfo} />
              </div>
            </div>
          );
        case "healthyLife":
          return (
            <div className="">
              <div className="contact_infomiddiv text-left">
                {props.contactInfo.cardIdDetails.contactNumber ? (
                  <>
                    <div className="contactinfo_label">{titles.PRE_NOTIFY}</div>
                    <div className="contactinfo_Value">
                      {props.contactInfo.cardIdDetails.contactNumber}
                    </div>
                    <div className="contactinfo_label">
                      {titles.CUSTOMER_CALL}
                    </div>
                    <div className="contactinfo_Value">1 (877) 987-1233</div>
                  </>
                ) : null}
                {canDisplayMedicine() && (
                  <div>
                    <div className="contactinfo_label ">
                      {titles.TELE_MEDICINE}
                    </div>
                    <div className="contactinfo_Value">1 (866) 697-8212</div>
                  </div>
                )}
                <div className="contactinfo_label">{titles.SEND_NEEDS}</div>
                <div className="contactinfo_Value">
                  {getSendNeedsToContactAddress()}
                </div>

                <AgentDetails agentInfo={props.contactInfo.agentInfo} />
              </div>
            </div>
          );
        case "AFMC":
          return (
            <div className="">
              <div className="contact_infomiddiv text-left">
                {props.contactInfo.cardIdDetails.contactNumber ? (
                  <>
                    <div className="contactinfo_label">
                      {titles.PRE_NOTIFY_SERVICE}
                    </div>
                    <div className="contactinfo_Value">
                      {props.contactInfo.cardIdDetails.contactNumber}
                    </div>
                  </>
                ) : null}
                {canDisplayMedicine() && (
                  <div>
                    <div className="contactinfo_label ">
                      {titles.TELE_MEDICINE}
                    </div>
                    <div className="contactinfo_Value">1 (888) 501-2405</div>
                  </div>
                )}
                <div className="contactinfo_label">{titles.SEND_NEEDS}</div>
                <div className="contactinfo_Value">
                  Arizona Foundation, P.O. Box 2909, Phoenix, AZ 85062-2909
                </div>
                <AgentDetails agentInfo={props.contactInfo.agentInfo} />
              </div>
            </div>
          );
      }
    })() || null
  );
};

const canDisplayMedicine = () => {
  const showTeleMedicine = ![
    "10051",
    "10052",
    "10053",
    "10061",
    "10062",
    "10063",
  ].includes(StorageHelpers.getLocalStorage("planId") || "");
  return showTeleMedicine;
};
