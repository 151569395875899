import config from "../../../config/constants/config";
import notificationNetwell from "../../../assests/images/netwell/notification_bell.svg";
import notificationUHF from "../../../assests/images/uhf/notification_bell.svg";
import nitificationInnovative from "../../../assests/images/innovative/my-notifications.svg";
import { apiService } from "carynhealth-component-library";
import { GetNotifcationsAPIType, NotificationsList } from "./interfaces";
import messages from "../../../config/constants/messages";
import ReminderIcon from "../../../assests/images/notification_reminder_icon.svg";
import AlertIcon from "../../../assests/images/notification_alert_icon.svg";
import UpdateIcon from "../../../assests/images/notification_update_icon.svg";
import SurveyIcon from "../../../assests/images/notification_survey_icon.svg";
import PromoIcon from "../../../assests/images/notification_promo_icon.svg";
import AnnouncementIcon from "../../../assests/images/notification_announcement_icon.svg";
import dummyUserInfo from "../../../dummyData/dummyUserInfo";

export const data = {
  UHF: {
    image: notificationUHF,
  },
  NETWELL: {
    image: notificationNetwell,
  },
  INNOVATION: {
    image: nitificationInnovative,
  },
};

export const getNotifcationsData = data[config.APP_NAME as keyof typeof data];

export const getNotifcationsAPI = async () => {
  try {
    const request = {
      URL:
        config.URLS.baseTwillo +
        config.URLS.getBaseNotificationURL +
        localStorage.getItem("userMail"),
      brand: config.APP_NAME,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    const { NotificationDetails, count } = apiResponse.response;
    const notificationsList = getSentNotifications(NotificationDetails);
    return {
      notificationsList,
      count,
      errorMessage: notificationsList.length ? "" : messages.NO_NOTIFICATIONS,
    };
  } catch (error: any) {
    return Promise.reject({
      errorMessage: messages.NO_NOTIFICATIONS || messages.SOMETHING_WRONG,
    });
  }
};

const notifyImages = {
  Alert: AlertIcon,
  REMINDER: ReminderIcon,
  UPDATE: UpdateIcon,
  SURVEY: SurveyIcon,
  PROMOTION: PromoIcon,
  ANNOUNCEMENT: AnnouncementIcon,
};

function getSentNotifications(
  data: GetNotifcationsAPIType[]
): Array<NotificationsList> {
  return data
    .filter(({ status }) => status === "sent")
    .slice(0, 5)
    .map((list) => {
      return {
        image: notifyImages[list.type as keyof typeof notifyImages],
        label: list.type,
        labelRight: dateformat(list.createdDate),
        description: list.message,
      };
    });
}

function dateformat(date: string) {
  const enrollmentDate = new Date(date);
  const nowdate = new Date();

  const formatedDate =
    enrollmentDate.getMonth() +
    "/" +
    enrollmentDate.getDate() +
    "/" +
    enrollmentDate.getFullYear();
  const todayDate =
    nowdate.getMonth() + "/" + nowdate.getDate() + "/" + nowdate.getFullYear();

  const hr = enrollmentDate.getHours();

  if (formatedDate !== todayDate) {
    return enrollmentDate.getMonth() + "/" + enrollmentDate.getDate();
  } else {
    if (hr === 0) {
      return enrollmentDate.getMinutes() + "mins";
    } else if (hr > 12) {
      return `${hr}:${enrollmentDate.getMinutes()} PM`;
    } else {
      return `${hr}:${enrollmentDate.getMinutes()} AM`;
    }
  }
}
