import config from "../../config/constants/config";

const bannerProps = ({ isSmallScreen }: { isSmallScreen: boolean }) => {
  return {
    logo: {
      alt: "company brand",
      src: `/login/${
        config.APP_NAME === config.BRAND_NETWELL
          ? "netwell-logo.png"
          : config.APP_NAME === config.BRAND_UHF
          ? "uhs-logo.svg"
          : "innovative-logo.png"
      }`,
      style: {},
    },
    banner: {
      alt: "company brand",
      src: `/login/${
        config.APP_NAME === config.BRAND_NETWELL
          ? "netwell-banner-desktop.png"
          : config.APP_NAME === config.BRAND_UHF
          ? "uhf_banner_desktop.png"
          : "innovative-banner-desktop.png"
      }`,
      style: {},
    },
  };
};

export const awsConfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_cognito_identity_pool_id,
  aws_user_pools_id: process.env.REACT_APP_AWS_user_pools_id,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_user_pools_web_client_id,
  oauth: {},
};

export const authenticationProps = ({
  isSmallScreen,
}: {
  isSmallScreen: boolean;
}) => {
  return {
    config: "LOGIN",
    authCallback: () => {},
    awsConfig,
    appConfig: {
      sessionURL: process.env.REACT_APP_API_BASE_URL,
      tokenUserName: process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
      tokenUserPassword: process.env.REACT_APP_GETWAY_TOKEN_PASS,
      brand: process.env.REACT_APP_ENV_NAME?.toLowerCase(),
    },
    bannerProps: bannerProps({ isSmallScreen }),
  };
};
