import React, { useEffect, useState } from "react";
import {
  changeMyBillingDate,
  getLastDigitAccountNumber,
  getReccuringDate,
} from "../../handlers";
import moment from "moment";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { ApiLoader, Dialog } from "carynhealth-component-library";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import { access } from "../../data";
import ModalChangePaymentMethod from "./ModalChangePaymentMethod";
import messages from "../../../../config/constants/messages";
import titles from "../../../../config/constants/titles";
import StyleWrapper from "../../Style";
import config from "../../../../config/constants/config";
const PaymentInformationScreen = (props: any) => {
  const [accountNo, setAccountno] = useState("");
  const [recurringDate, setRecurringDate] = useState(null);
  const [openModalChangePayment, setOpenModalchangePayment] = useState(false);
  const [changeBillingDate, setChangeBillingDate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [AccessControl, setAccessControl] = useState(access);

  useEffect(() => {
    getLastDigitAccount();
    contolCard();
  }, []);

  const contolCard = () => {
    let Access = AccessControl;

    Access.control.changePaymentAccess =
      getLocalStorage("SOURCE") !== "NEO" &&
      getLocalStorage("SOURCE") !== "Optimed";
    Access.control.showPaymentType = getLocalStorage("SOURCE") !== "NEO";
    Access.control.showNextPaymentDueDate = getLocalStorage("SOURCE") !== "NEO";
    setAccessControl(Access);
  };

  const getLastDigitAccount = () => {
    setLoader(true);
    getLastDigitAccountNumber()
      .then((res: any) => {
        setLoader(false);
        setAccountno(res.data.response.replaceAll("null", "****"));
      })
      .catch((err: any) => {
        setLoader(false);
        console.log(err);
      });
    getReccuringDate()
      .then((res: any) => {
        setLoader(false);
        setRecurringDate(res.data.response.recurringDate);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log(err);
      });
  };
  const closeModalPopup = () => {
    setOpenModalchangePayment(false);
    getLastDigitAccount();
    props.resetPaymentMethod();
  };
  const changeBillingMethod = () => {
    setLoader(true);
    changeMyBillingDate()
      .then((res) => {
        setLoader(false);
        setErrMessage(true);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  return (
    <>
      <StyleWrapper>
        {loader && <ApiLoader />}
        <div className="paymentBlock">
          <span className="PaymentInfoTitle">{titles.paymentInfo}</span>
          <div style={{ marginTop: "20px" }}>
            {getLocalStorage("SOURCE") !== "Optimed" && (
              <>
                {" "}
                <div>
                  <span className="Title">{titles.currentPaymentMethod}</span>
                  <br />
                  {access.control.showPaymentType ? (
                    <span className="numberPaymentMethod">
                      {props.cardDeatils}
                    </span>
                  ) : (
                    <span className="numberPaymentMethod">N/A</span>
                  )}
                </div>
                <div style={{ marginTop: "63px" }}>
                  <table className="Last_4_digit">
                    <td className="td_style1">
                      {messages.AccountNumberOnFile}
                    </td>
                    <td
                      className="td_style"
                      style={{ verticalAlign: "middle" }}
                    >
                      {accountNo}
                    </td>
                  </table>
                </div>
              </>
            )}
            <div style={{ paddingTop: "10px" }}>
              {access.control.showchangePaymentMethod && (
                <button
                  type="button"
                  onClick={() => setOpenModalchangePayment(true)}
                  className="newblueActionBtn border-0 mr-2 text-uppercase"
                >
                  {messages.changePaymentMethod}
                </button>
              )}
            </div>
            <hr />
            {access.control.showChangeBillingDate && (
              <div>
                <span className="Title">{messages.NextPaymentDueDate}</span>
                <br />
                <span className="numberPaymentMethod">
                  {access.control.showNextPaymentDueDate
                    ? recurringDate &&
                      moment(recurringDate).format("MMM DD YYYY")
                    : "N/A"}
                </span>
                <div>
                  <button
                    type="button"
                    onClick={() => setChangeBillingDate(true)}
                    className="newblueActionBtn border-0 mr-2 text-uppercase"
                    style={{ marginTop: "10px" }}
                  >
                    {messages.CHANGEBILLINGDATE}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {access.control.changePaymentAccess ? (
          <ModalChangePaymentMethod
            openModalChangePayment={openModalChangePayment}
            closePopup={() => closeModalPopup()}
            accountNo={accountNo}
          />
        ) : (
          openModalChangePayment && (
            <Dialog
              brand={
                config.APP_NAME === config.BRAND_NETWELL
                  ? "NETWELL"
                  : config.APP_NAME === config.BRAND_UHF
                  ? "UHF"
                  : "INNOVATION"
              }
              show={true}
              body={() => (
                <>
                  {config.APP_NAME === config.BRAND_NETWELL ? (
                    <p style={{ whiteSpace: "pre" }}>
                      {messages.netwell.PaymentMethod}
                    </p>
                  ) : (
                    <p>
                      To change your payment method call Member Services team at
                      : {getLocalStorage("CONTACT_NUMBER")} ,  Monday through Friday 9:00 am to 6:00 pm EST{" "}
                    </p>
                  )}
                </>
              )}
              CloseIcon={true}
              title={"Message"}
              buttons={[
                {
                  label: "OK",
                  size: "large",
                  variant: "text",
                  type: "button",
                  className: "Ok",
                } as CustomButtonProps,
              ]}
              handleCallback={(data: any) => {
                closeModalPopup();
              }}
            />
          )
        )}

        {changeBillingDate && (
          <Dialog
            brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
            show={changeBillingDate}
            title={"Message"}
            body={() => <p>{messages.ChangeBillingDateMessage}</p>}
            CloseIcon={true}
            buttons={[
              {
                label: "PROCEED",
                size: "large",
                variant: "text",
                type: "button",
                className: "PROCEED",
              } as CustomButtonProps,
              {
                label: "CANCEL",
                size: "large",
                variant: "text",
                type: "button",
                className: "CANCEL",
              } as CustomButtonProps,
            ]}
            handleCallback={(data: any) => {
              if (data.label === "CANCEL") {
                setChangeBillingDate(false);
              } else if (data.label === "PROCEED") {
                changeBillingMethod();
                setChangeBillingDate(false);
              } else {
                setChangeBillingDate(false);
              }
            }}
          />
        )}
        {errMessage && (
          <Dialog
            brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
            show={errMessage}
            title={"Message"}
            body={() => <p>{messages.SubmittedRequest}</p>}
            CloseIcon={true}
            buttons={[
              {
                label: "OK",
                size: "large",
                variant: "text",
                type: "button",
                className: "OK",
              } as CustomButtonProps,
            ]}
            handleCallback={(data: any) => {
              setChangeBillingDate(false);
            }}
          />
        )}
      </StyleWrapper>
    </>
  );
};
export default PaymentInformationScreen;
