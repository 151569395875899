import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

export const documentsInfo = {
  UHF: {
    documents: [
      {
        description: "Welcome Booklet",
        disable: "disableUHFWelcome",
        function: "getBookletLink",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/BIG/UHS-Welcome-Booklet.pdf",
      },
      {
        description: "Sharing Guidelines",
        disable: "disableUHFSharing",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-Sharing-Program-Guidelines.pdf",
        function: "opensharingguidlines",
      },
      {
        description: "Member Responsibilities",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS+Member+Responsibilities.pdf",
      },
      {
        description: "Statement of Shared Faith and Beliefs",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Statement-of-Shared-Faith-%26-Beliefs.pdf",
      },
      {
        description: "Get Started with Health Sharing",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+How+do+I+Get+Started+with+Health+Sharing.pdf",
      },
      {
        description: "5 Questions about Health Sharing",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+5+Questions+about+Health+Sharing.pdf",
      },
      {
        description: "Tips on Telemedicine",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Tips+on+Telemedicine.pdf",
        show: () =>
          getLocalStorage("NETWORK_NAME") !== "THS" &&
          !["10051", "10052", "10053"].includes(
            getLocalStorage("planId") || ""
          ),
      },
      {
        description: "4 Great Reasons to share UHS",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+4+Great+Reasons+to+share+Universal+HealthShare+with+Your+Friends.pdf",
      },
      {
        description: "Pharmacy Vaccine Reimbursement Transmittal",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Pharmacy-Vaccine-Reimbursement-Transmittal.pdf",
      },
    ],
    "not-found": `We’re facing some technical difficulties, due to which this feature is currently unavailable. For support, call Member
        Services at ${localStorage.getItem(
          "CONTACT_NUMBER"
        )},  Monday through Friday 9:00 am to 6:00 pm EST.`,
  },
  NETWELL: {
    documents: [
      {
        id: [
          "1004",
          "2004",
          "2005",
          "2006",
          "2007",
          "1005",
          "2013",
          "1007",
          "1006",
          "1008",
          "1009",
          "2021",
          "1010",
          "2018",
        ],
        list: [
          {
            description: "netWell Comprehensive Book",
            link: "https://netwell-prod.s3.amazonaws.com/Member/netWell-Comprehensive-Book.pdf",
          },
          {
            description: "Elite+ Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/Elite%2B-Program-Grid.pdf",
          },
          {
            description: "Elite+ Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/Elite%2B-Brochure.pdf",
          },
          {
            description: "netWellRX Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/netWellRX-Brochure.pdf",
          },
        ],
      },
      {
        id: ["2020"],
        list: [
          {
            description: "PURE Catastrophic Member Guide",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/PURE-Catastrophic-Member-Guide.pdf",
          },
          {
            description: "PURE Catastrophic Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/PURE-Catastrophic-Program-Grid.pdf",
          },
          {
            description: "PURE Catastrophic Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/PURE-Catastrophic-Brochure.pdf",
          },
        ],
      },
      {
        id: ["1002", "2003", "2012", "2014", "2015", "2016", "2017", "2019"],
        list: [
          {
            description: "WellLife+ Member Guide",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Member-Guide.pdf",
          },
          {
            description: "WellLife+ Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Program-Grid.pdf",
          },
          {
            description: "WellLife+ Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Brochure.pdf",
          },
          {
            description: "netWellRX Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/netWellRX-Brochure.pdf",
          },
        ],
      },
      {
        id: [
          "1004",
          "2004",
          "2005",
          "2006",
          "2007",
          "2013",
          "1007",
          "1006",
          "1008",
          "1009",
          "2021",
          "1010",
          "2018",
        ],
        list: [
          {
            description: "netWell Comprehensive Book",
            link: "https://netwell-prod.s3.amazonaws.com/Member/netWell-Comprehensive-Book.pdf",
          },
          {
            description: "Elite+ Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/Elite%2B-Brochure.pdf",
          },
          {
            description: "Elite+ Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/Elite%2B-Program-Grid.pdf",
          },
          {
            description: "WellLife+ Member Guide",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Member-Guide.pdf",
          },
          {
            description: "WellLife+ Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Brochure.pdf",
          },
          {
            description: "WellLife+ Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Program-Grid.pdf",
          },
          {
            description: "netWellRX Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/netWellRX-Brochure.pdf",
          },
        ],
      },
    ],
    "not-found":
      "We are currently updating this feature and will be up shortly. Stay tuned!",
  },
};
