import { apiService } from "carynhealth-component-library";
import { useContext } from "react";
import config from "../../config/constants/config";
import messages from "../../config/constants/messages";
import { AuthContext } from "../../context/AuthenticateContext";
import { EnrolledMemberData } from "./interface";
import axios from "axios";
import { setLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

export const getEnrolledMemberDataAPI = async (id: any) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.getCsrTempEmpId,
      brand: config.APP_NAME,
      data: { subscriberIdSource: id },
    };
    const { data: apiResponse } = await apiService.default.post(request);

    const EnrolledMemberDataList = setEnrolledMemberData(apiResponse);
    return {
      apiResponse,
      errorMessage: EnrolledMemberDataList.length
        ? ""
        : messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);
    if(localStorage.getItem("userMail") === null){
      sessionStorage.clear();
      window.location.reload();
    }
    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

function setEnrolledMemberData(data: any[]): Array<EnrolledMemberData> {
  return data.map((list) => {
    return {
      data: list,
    };
  });
}

export const getHippaAuth = async (email: string) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.getHippaAuth + email,
      brand: config.APP_NAME,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    const HippaAuthData = apiResponse.response;
    const HippaAuthList = setEnrolledMemberData(HippaAuthData);
    return {
      HippaAuthData,
      errorMessage: HippaAuthList.length ? "" : messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const updateHippaStatus = async (payload: object, endPoint: string) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + endPoint,
      brand: config.APP_NAME,
      data: payload,
    };
    const { data: apiResponse } = await apiService.default.post(request);
    return {
      apiResponse,
      errorMessage: messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const getHippaAuthById = async (id: number) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.gethipaaAuthById + id,
      brand: config.APP_NAME,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    const HippaAuthData = apiResponse.response;
    return {
      HippaAuthData,
      errorMessage: HippaAuthData.length ? "" : messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const getIpAddress = async () => {
  try {
    const URL = config.URLS.getGeoLocation;
    const { data: apiResponse } = await axios.get(URL);
    const Data = apiResponse;
    return {
      Data,
      errorMessage: Data.length ? "" : messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const getUploadImage = async (img: any) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.getUploadImage,
      brand: config.APP_NAME,
      data: img,
    };
    const { data: apiResponse } = await apiService.default.post(request);
    return {
      apiResponse,
      errorMessage: messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const SetSaveHippaPrivacyAuth = async (data: any) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.saveHippaPrivacyAuth,
      brand: config.APP_NAME,
      data: data,
    };
    const { data: apiResponse } = await apiService.default.post(request);
    return {
      apiResponse,
      errorMessage: messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);
    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const getHippaEsignPdf = async (id: number) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.gethipaaAuthById + id,
      brand: config.APP_NAME,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    const HippaAuthData = apiResponse.response;
    return {
      HippaAuthData,
      errorMessage: HippaAuthData.length ? "" : messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const getGateWayToken: any = async () => {
  try {
    let request = {
      username: process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
      password: process.env.REACT_APP_GETWAY_TOKEN_PASS,
    };
    let payload = {
      data: request,
      URL: config.URLS.baseAPI + config.URLS.loginForhipaa,
      brand: config.APP_NAME,
      disableConfig: true,
    };
    const data = await apiService.default.post(payload);
    let bearer = data["headers"].authorization as string;
    var array = bearer.split("Bearer ");
    setLocalStorage("bearerToken", array[1]);
    return array;
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};
