import { useEffect, useState } from "react";
import { apiService, StorageHelpers } from "carynhealth-component-library";
import { getContactDataIP } from "../handler";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getEnrolledMemberDataAPI } from "../../../../UI/HippaCard/handlers";

export const ContactInnovative: React.FC<any> = (props: any) => {
  const NETWORK_NAME = StorageHelpers.getLocalStorage("NETWORK_NAME") || "";
  const [contactInfo, setContactInfo] = useState([]);
  const planId: any = getLocalStorage("planId");
  let [Version, setVersion] = useState("v2");

  const getcontact = () => {
    let vers = 'v1'
    getEnrolledMemberDataAPI(getLocalStorage("sourceid"))
      .then((res: any) => {
        var inputDate = isDateGreaterThanMarch3_2024(
          res.apiResponse[0].benefits[0].benefitBegin
        );
        // vers = inputDate ? "v2" : "v1"
        // setVersion(vers);
        
        getContactDataIP(planId, Version)
          .then((res:any) => {
            setContactInfo(res.apiResponse.response);
          })
          .catch((error:any) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function isDateGreaterThanMarch3_2024(date: string | number | Date) {
    // Create a Date object for March 1, 2024
    var march1_2024 = new Date(2024, 2, 1); // Note: Month is zero-based in JavaScript Date objects (0 = January, 1 = February, ..., 11 = December)

    // Convert the input date string to a Date object
    var inputDate = new Date(date);

    // Check if the input date is greater than March 1, 2024
    return inputDate >= march1_2024;
  }

  useEffect(() => {
    getcontact();
  }, []);

  return (
    <>
      <div className="contact_infomiddiv text-left">
        {contactInfo.map((value: any, key: number) => (
          <>
            <div className="contactinfo_label">{value.label}</div>
            <div className="contactinfo_Value">{value.value}</div>
          </>
        ))}
      </div>
    </>
  );
};
