import React, { useMemo } from "react";
import { Summary } from "carynhealth-component-library";
import config from "../../../config/constants/config";
import { styled } from "@mui/system";
import { SummaryProps } from "carynhealth-component-library/dist/cjs/components/ProgramInformation/Summary";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getPlainIData } from "../../RightTopMemberTabs/handler";
import messages from "../../../config/constants/messages";

const isNetwell = config.APP_NAME === config.BRAND_NETWELL;

export interface SummaryDetails {
  title: string;
  subTitle: string;
  progress: number;
  met: number;
  remaining: number;
}

export interface ProgramName {
  title: string;
  subTitle: string;
}

const SummaryStyle = styled("section")(({ theme }) => {
  return {
    margin: "23px 5px 10px 3px",
    ".MuiButtonBase-root": {
      background: "initial !important",
    },
    ".pi-summary_title": {
      fontSize: 40,
    },
    ".pi-summary_description": {
      width: config.APP_NAME === config.BRAND_INNOVATION ? "700px" : "auto",
    },
    [theme.breakpoints.down(426)]: {
      ".pi-summary_description": {
        width: "auto",
      },
    },
  };
});

const ProgramInfoSummary: React.FC<{ data: any }> = (props) => {
  const setDesc = (name: string) => {
    let programName = name?.replace(/[0-9\s]/g, "");
    switch (programName.toLocaleLowerCase()) {
      case "uhs":
        return {
          description: messages.uhf.PROGRAM_DESCRIPTION.UHSStandardPrograms,
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf",
        };
      case "uhs smartshare":
        return {
          description: messages.uhf.PROGRAM_DESCRIPTION.UHSSmartShare,
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-SmartShare.pdf",
        };
      case "ths":
        return {
          description: messages.uhf.PROGRAM_DESCRIPTION.UHSThriveHealthShare,
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Thrive-Program-Grid.pdf",
        };
      case "hl":
        return {
          description: messages.uhf.PROGRAM_DESCRIPTION.UHSHealthyLife,
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf",
        };
      case "es":
        return {
          description: messages.uhf.PROGRAM_DESCRIPTION.UHSEssentialShare,
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-EssentialShare-Program-Grid.pdf",
        };
      case "chs":
        return {
          description: messages.uhf.PROGRAM_DESCRIPTION.UHSCommunityHealthShare,
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Community-HealthShare-Program-Grid.pdf",
        };
      case "optimum":
        return {
          description: messages.innovative.PROGRAM_DESCRIPTION.Optimum,
          URL: "https://innovative-partners-s3.s3.us-east-2.amazonaws.com/Agent/Optimum-Plan-Brochure.pdf",
        };
      case "elite":
        return {
          description: messages.innovative.PROGRAM_DESCRIPTION.Elite,
          URL: "https://innovative-partners-s3.s3.us-east-2.amazonaws.com/Agent/Elite-Plan-Brochure.pdf",
        };
      case "optimum-md" :
        return {
          description: messages.innovative.PROGRAM_DESCRIPTION.Optimum,
          URL: "https://innovative-partners-s3.s3.us-east-2.amazonaws.com/Agent/Optimum-MD-Plan-Brochure.pdf",
        };
        case "elitemd":
        return {
          description: messages.innovative.PROGRAM_DESCRIPTION.Elite,
          URL: "https://innovative-partners-s3.s3.us-east-2.amazonaws.com/Agent/Elite-MD-Plan-Brochure.pdf",
        };
      default:
        return { description: "", URL: "" };
    }
  };

  const prgrmData = useMemo(() => {
    const { data } = props;
    const planID: any = getLocalStorage("planId");
    const removeHeader =
      ["smartshare50", "smartshare25"].includes(
        localStorage.getItem("NETWORK_NAME") || ""
      ) ||
      config.APP_NAME === config.BRAND_NETWELL ||
      config.APP_NAME === config.BRAND_INNOVATION ||
      getPlainIData(planID);
    const removeShare =
      !data.acsmmet &&
      !data.acsmremain &&
      !data.acsmTotal &&
      config.APP_NAME === config.BRAND_INNOVATION;
    const removeChangeProgram = config.APP_NAME === config.BRAND_NETWELL;
    const programName = localStorage.getItem("NETWORK_NAME");
    return {
      programName: {
        title: programName || data.programname,
        subTitle: data.programname2,
        description: setDesc(programName || data.programname).description,
        URL: setDesc(programName || data.programname).URL,
      },
      headerSumary: removeHeader
        ? undefined
        : {
            title: "Summary",
            subTitle: "Non-Sharable amount",
            progress: data.barRange,
            met: data.nsamet,
            remaining: data.nsaremain,
            style: { backgroundColor: "rgba(235,87,87,.5)" },
            indicatorColor: "#eb5757",
          },
      shareSummary: removeShare
        ? undefined
        : {
            title: "",
            subTitle: isNetwell
              ? "Non-Sharable amount"
              : "Annual Co-Share Maximum Amount",
            progress: isNaN(data.barRangeYellow) ? 0 : data.barRangeYellow,
            met: data.acsmmet,
            remaining: data.acsmremain,
            style: { backgroundColor: "rgba(254,196,101,.5)" },
            indicatorColor: "#fec465",
          },
      changeProgram: removeChangeProgram
        ? null
        : {
            url: config.URLS.baseAPI + config.URLS.caseCreation,
            Subject: localStorage.getItem("sourceid") || "",
            Origin: "External",
            External_Application_Name__c: "Member Portal",
            Status: "New",
            Type: "Account Update",
            SuppliedEmail: localStorage.getItem("userMail") || "",
            Description: "Request to change",
            AppName: config.APP_NAME,
          },
    };
  }, [props]);

  return (
    <SummaryStyle id="pi-summary" className={`${config.APP_NAME}_summary`}>
      <Summary {...(prgrmData as SummaryProps)} />
    </SummaryStyle>
  );
};

export default ProgramInfoSummary;
