import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { tableHeader } from "../../data";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import moment from "moment";
import StyleWrapper from "../../Style";
import { getDate, numberWithCommas, floatconversion, access } from "../../data";
import { EnhancedTableHead } from "carynhealth-component-library";
import { headCells } from "./handler";
import {
  stableSort,
  getComparator,
} from "carynhealth-component-library/dist/cjs/components/EnhancedTableHead/handlers";

type Order = "asc" | "desc";

const rows = [
  createDataOfFilter(
    "600016",
    "2020-04-20",
    "VITALCARE FP LLC",
    670,
    43,
    "FINAL"
  ),
];

function createDataOfFilter(
  no: any,
  date_of_service: string,
  provider: string,
  charged: number,
  paid: number,
  status: string
) {
  return { no, date_of_service, provider, charged, paid, status };
}

function createData(
  transactionId: string,
  createdDate: string,
  transaction_date: string,
  payment_method: string,
  type: string,
  transactionAmount: string,
  transaction_amount: string,
  authorizationMessage: string,
  monthlyShare: string,
  monthly_share: string,
  uhfMonthlyFee: string,
  ufh_monthly_fee: string,
  applicationFee: string,
  application_fee: string,
  refundAmount: string,
  guardianAmount: any,
  essentialAmount: any,
  dentalAmount: any
) {
  return {
    transactionId,
    createdDate,
    transaction_date,
    payment_method,
    type,
    transactionAmount,
    transaction_amount,
    authorizationMessage,
    monthlyShare,
    guardianAmount,
    monthly_share,
    uhfMonthlyFee,
    ufh_monthly_fee,
    applicationFee,
    application_fee,
    refundAmount,
    essentialAmount,
    dentalAmount,
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const innovativeAccess = access.control.type == "INNOVATION";

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ padding: 5 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell style={style.tableRow} component="th" scope="row">
          {row.transactionId}
        </TableCell>
        <TableCell style={style.tableRow} align="left">
          {getDate(row.createdDate ? row.createdDate : row.transaction_date)}
        </TableCell>
        <TableCell style={style.tableRow} align="left">
          {row.payment_method ? row.payment_method : row.type}
        </TableCell>
        <TableCell style={style.tableRow} align="left">
          {row.transactionAmount
            ? "$" + row.transactionAmount
            : "$" + row.transaction_amount}
        </TableCell>
        <TableCell style={style.tableRow} align="left">
          <span className={"web_status_button " + row.authorizationMessage}>
            {row.authorizationMessage}
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={12}>
          <Collapse
            in={open}
            timeout="auto"
            component="tr"
            unmountOnExit
            style={{ backgroundColor: "#f6f6f6" }}
          >
            <Box sx={{ margin: 0 }}>
              <TableRow>
                <TableCell
                  component="td"
                  align="left"
                  className="innerTh blankCell"
                  style={{ width: "6%" }}
                >
                  &nbsp;
                </TableCell>
                {tableHeader.heading.child.map((value) => (
                  <TableCell
                    align="left"
                    className="innerTh"
                    style={{ width: "370px" }}
                  >
                    {value}
                  </TableCell>
                ))}
              </TableRow>

              {innovativeAccess ? (
                <TableRow>
                  <TableCell
                    component="td"
                    align="left"
                    className="innerTh blankCell"
                    style={{ width: "6%" }}
                  >
                    &nbsp;
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="innerTh"
                    align="left"
                  >
                    {row.essentialAmount ? (
                      <>
                        $
                        {numberWithCommas(floatconversion(row.essentialAmount))}
                      </>
                    ) : row.essentialAmount ? (
                      <>
                        {numberWithCommas(floatconversion(row.essentialAmount))}
                      </>
                    ) : (
                      "$0"
                    )}
                  </TableCell>
                  <TableCell align="left" className="innerTh">
                    {row.guardianAmount ? (
                      <>
                        ${numberWithCommas(floatconversion(row.guardianAmount))}
                      </>
                    ) : row.guardianAmount ? (
                      <>
                        {numberWithCommas(floatconversion(row.guardianAmount))}
                      </>
                    ) : (
                      "$0"
                    )}
                  </TableCell>
                  <TableCell align="left" className="innerTh">
                    {row.dentalAmount ? (
                      <>
                        ${numberWithCommas(floatconversion(row.dentalAmount))}
                      </>
                    ) : row.dentalAmount ? (
                      <>{numberWithCommas(floatconversion(row.dentalAmount))}</>
                    ) : (
                      "$0"
                    )}
                  </TableCell>
                  <TableCell align="left" className="innerTh">
                    {row.monthlyShare ? (
                      <>
                        ${numberWithCommas(floatconversion(row.monthlyShare))}
                      </>
                    ) : row.monthlyShare ? (
                      <>{numberWithCommas(floatconversion(row.monthlyShare))}</>
                    ) : (
                      "$0"
                    )}
                  </TableCell>

                  <TableCell align="left" className="rowcellcolor rowTableCell">
                    {row.applicationFee ? (
                      <>
                        ${numberWithCommas(floatconversion(row.applicationFee))}
                      </>
                    ) : row.application_fee ? (
                      <>
                        {numberWithCommas(floatconversion(row.application_fee))}
                      </>
                    ) : (
                      "$0"
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    component="td"
                    align="left"
                    className="innerTh blankCell"
                    style={{ width: "6%" }}
                  >
                    &nbsp;
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="innerTh"
                    align="left"
                  >
                    {row.monthlyShare ? (
                      <>
                        ${numberWithCommas(floatconversion(row.monthlyShare))}
                      </>
                    ) : row.monthly_share ? (
                      <>
                        {numberWithCommas(floatconversion(row.monthly_share))}
                      </>
                    ) : (
                      "$0"
                    )}
                  </TableCell>
                  <TableCell align="left" className="innerTh">
                    {row.uhfMonthlyFee ? (
                      <>
                        ${numberWithCommas(floatconversion(row.uhfMonthlyFee))}
                      </>
                    ) : row.ufh_monthly_fee ? (
                      <>
                        {numberWithCommas(floatconversion(row.ufh_monthly_fee))}
                      </>
                    ) : (
                      "$0"
                    )}
                  </TableCell>
                  <TableCell align="left" className="innerTh">
                    {row.applicationFee ? (
                      <>
                        ${numberWithCommas(floatconversion(row.applicationFee))}
                      </>
                    ) : row.application_fee ? (
                      <>
                        {numberWithCommas(floatconversion(row.application_fee))}
                      </>
                    ) : (
                      "0"
                    )}
                  </TableCell>

                  <TableCell align="left" className="rowcellcolor rowTableCell">
                    ${numberWithCommas(row.refundAmount)}
                  </TableCell>
                </TableRow>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function MyTransactionTable(props: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [transactionData, setTransaction] = useState<any[]>(
    props.transactionData
  );
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("service_date");
  const [selected, setSelected] = React.useState<any>([]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (
    event: any,
    property: React.SetStateAction<string>
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <StyleWrapper>
        <TableContainer
          component={Paper}
          style={{ boxShadow: "none", color: "#454d58", height: 400 }}
        >
          <Table aria-label="collapsible table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {transactionData.length > 0 &&
                stableSort(transactionData, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any) => <Row key={row.transactionId} row={row} />)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 100]}
          count={transactionData.length}
          page={page}
          sx={{
            ".Mui-disabled": {
              backgroundColor: "transparent !important",
              color: "rgba(0, 0, 0, 0.26)!important",
            },
          }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyleWrapper>
    </div>
  );
}
const style = {
  tableRow: {
    padding: "5px 16px",
    fontSize: 16,
  },
};
