import { apiService } from "carynhealth-component-library";
import config from "../../../config/constants/config";
import UHFDocuments from "../../../assests/images/uhf/documents.svg";
import NetwellDocuments from "../../../assests/images/netwell/documents.svg";
import InnovativeDocuments from "../../../assests/images/innovative/documents-icon.svg";
import { DetailsType } from "./interface";
import titles from "../../../config/constants/titles";
import messages from "../../../config/constants/messages";
import {getLocalStorage} from "carynhealth-component-library/dist/cjs/helpers/storage";

const brand = config.APP_NAME;

export const getDocumentsDataByID = async (planId: any, memberSSN: any) => {
  let URL: string =
    config.URLS.baseAPI + config.URLS.getPartnerDocumentByPlanId + planId;
  const sourceid = getLocalStorage("sourceid");
  if (config.APP_NAME === config.BRAND_INNOVATION)
    URL =
      config.URLS.baseAPI +
      config.URLS.getPartnerDocumentByPlanId +
      planId +
      "/" +
      memberSSN + '/' + sourceid
  try {
    const request = {
      URL: URL,
      brand: config.APP_NAME,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    return {
      title: titles.PartnershipDocuments,
      image:
        brand === config.BRAND_NETWELL
          ? NetwellDocuments
          : brand === config.BRAND_UHF
          ? UHFDocuments
          : InnovativeDocuments,
      isUHF: brand === config.BRAND_UHF,
      apiResponse,
      errorMessage: messages.NO_DOCUMENTS,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.NO_DOCUMENTS,
    });
  }
};
