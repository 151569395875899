import { apiService } from "carynhealth-component-library";
import config from "../../config/constants/config";

export const getRasaAIresponse = async (obj: any) => {
  try {
    const request = {
      URL: process.env.REACT_APP_RASABOT + "",
      data: obj,
    };
    const response = await apiService.default.post(request);
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export const getsharingguidlineslink = async () => {
  let mail = localStorage.getItem("userMail");
  let obj = { email: mail, type: "guidelines" };

  try {
    const request = {
      URL: process.env.REACT_APP_API_BASE_URL + "v1/memberportal/planinfo",
      data: obj,
    };
    const response = await apiService.default.post(request);
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export const encryptMemberId = async (encodeString: any) => {
  let obj = {
    memberId: `memberid=${localStorage.getItem(
      "sourceid"
    )}&type=&email=${localStorage.getItem("userMail")}`,
  };
  let request = {
    URL: config.URLS.baseAPI + "v1/csrportal/idencryption",
    data: obj,
    brand: config.APP_NAME,
  };
  try {
    var response = await apiService.default.post(request);
    return response.data;
  } catch (err) {
    if(localStorage.getItem("userMail") === null){
      sessionStorage.clear();
      window.location.reload();
    }
    console.log("Internal server error.");
  }
};
